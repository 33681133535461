.home-container {
	min-width: 100%;
	max-width: 100vw;
	height: 100%;
	overflow-x: hidden;
	position: relative;

	//^--------------------------- HERO -------------------------- //

	.hero {
		width: 100%;
		height: 100vh;
		background-image: url('../../images/green-background.svg');
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;
		position: relative;

		.curtain-left,
		.curtain-right {
			height: 100vh;
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 0;
		}

		.curtain-left {
			left: 0;
		}

		.curtain-right {
			right: 0;
		}

		.content {
			width: 85%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			padding-top: 1rem;
			text-align: center;
	

			.text-container {
				width: 95%;
				height: 50%;
				text-transform: uppercase;
				position: relative;
				text-shadow: 0px 1px 3px $c-dark-green;
				color: $c-very-white;
				position: absolute;
				top: 1rem;
				left: 0.5rem;
				margin: 0 auto;

				.title {
					font-family: bebas;
					font-size: 2.5rem;
					margin: 1rem 0;
				}

				.subtitle {
					font-family: bebas;
					font-size: 1.5rem;
					margin-bottom: 0.5rem;
				}

				.subsubtitle {
					font-family: oswald-regular;
					font-size: 1rem;
					line-height: 1.2rem;
				}

				.event {
					font-family: oswald-light;
					font-size: 1rem;
					line-height: 1.2rem;

					.next-event {
						font-family: 'oswald-semibold';
					}
				}
			}

			.image-container {
				min-width: 60%;
				height: 35vh;
				display: flex;
				flex-direction: column;
				animation: changeBg 10s infinite;
				position: absolute;
				bottom: 4rem;
				left: 19%;

				.logo {
					width: 180px;
					position: absolute;
					bottom: 0.8rem;
					left: 1rem;
				}

				.citation {
					width: 95vw;
					position: absolute;
					bottom: -1.9rem;
					left: -3.5rem;
					color: $c-white;
					font-family: montserrat-light;
					font-size: 0.6rem;

					.italic {
						width: 95vw;
						font-style: italic;
					}
				}
			}
		}

		.fa-arrow {
			position: absolute;
			left: 48%;
			bottom: 0.3rem;
			font-size: 0.9rem;
			animation: slidedown 1.3s ease-in-out 0s infinite running;
		}

		.arrow-up {
			margin-bottom: 0.4rem;
		}
	}

	//^------------------------- ACTUALITY ------------------------- //

	.actuality {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 5rem;
		padding-top: 3rem;

		.container {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			align-items: center;

			.image-container {
				position: relative;
			}
			
			.image {
				width: 280px;
				margin: 0 auto;
			}

			.sub-image {
				width: 100px;
				height: auto;
				position: absolute;
				top: -1rem;
				right: -2rem;
				z-index: 2;
			}

			.content {
				width: 100%;
				height: 80%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: auto 0;
				text-align: right;

				.title {
					font-family: bebas;
					text-transform: uppercase;
					font-size: 4rem;
					line-height: 5rem;
					margin-bottom: 3rem;
					text-shadow: 0px 2px 3px $c-dark-green;
					color: $c-very-white;

					.title-span {
						margin-right: 1rem;
					}
				}

				.text-container {
					width: 95%;
					height: 50%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: 0 auto;

					.text {
						font-family: montserrat-regular;
						font-size: 1.2rem;
						line-height: 1.5rem;
						text-align: left;

						& > * {
							margin-bottom: 1rem;
						}
					}

					.text-2 {
						font-family: montserrat-regular;
						font-size: 1.2rem;
						line-height: 1.5rem;
						text-align: right;
					}

					.infos {
						width: 75%;
						font-family: oswald-regular;
						text-transform: uppercase;
						font-size: 1.5rem;
						line-height: 2rem;
						margin: 1rem 0 2rem 0;
						align-self: flex-end;
						color: $c-very-white;
					}
				}
			}
		}

		.actuality-btn {
			margin: 1rem 2rem 2rem auto;
		}
	}

	//^-------------------------- AGENDA -------------------------- //

	.agenda {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		padding: 3rem 0;

		.content {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: auto 0;
			text-align: left;

			.title {
				font-family: bebas;
				text-transform: uppercase;
				font-size: 4rem;
				line-height: 5rem;
				margin-bottom: 3rem;
				text-shadow: 0px 2px 3px $c-dark-green;
				color: $c-very-white;

				.title-span {
					margin-left: 1rem;
				}
			}

			.text-container {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;

				.text {
					width: 95%;
					font-family: oswald-regular;
					font-size: 1.2rem;
					line-height: 1.6rem;
					text-align: left;
					text-transform: uppercase;
					margin: 0 auto;

					& > * {
						margin-bottom: 1rem;
					}
				}

				.program {
					width: 100%;
					margin-top: 3rem;
					display: flex;
					flex-direction: column;
					align-items: center;

					.title-program {
						width: 100%;
						font-family: oswald-regular;
						font-size: 1.5rem;
						line-height: 2rem;
						text-transform: uppercase;
						text-align: center;
						margin-bottom: 2rem;
						padding: 1rem 0;
						background-color: $c-blood-cherry;
						color: $c-very-white;
					}

					.program-content {
						width: 100%;

						.show-infos {
							width: 95%;
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							flex-wrap: wrap;
							margin: 2rem auto 4rem auto;
							position: relative;

							.image-show {
								width: 300px;
								margin-bottom: 2rem;
							}

							.text-show {
								width: 95%;

								.date {
									font-family: oswald-extra-light;
									font-size: 1.5rem;
									margin-bottom: 0.5rem;
									text-transform: uppercase;
									color: $c-very-white;
								}

								.title-show {
									font-family: oswald-semibold;
									font-size: 2rem;
									line-height: 2.5rem;
									text-transform: uppercase;
									margin-bottom: 1rem;
									color: $c-cherry-red;
									text-shadow: 0px 2px 4px $c-very-dark-green;
								}

								.details {
									font-family: montserrat-regular;
									font-size: 1.2rem;
									line-height: 1.5rem;
								}
							}

							&:nth-child(2n) {
								flex-direction: column-reverse;
								align-content: center;
							}


							&::after {
								content: "";
								width: 25%;
								height: 5px;
								border-radius: 20px;
								background-color: $c-blood-cherry;
								position: absolute;
								bottom: -2rem;
								left: 35%;
								z-index: 50;
							}

							&:last-child::after {
								display: none;
							}
						}
					}
				}

				.program-btn {
					background-color: $c-dark-water-green;

					.fa-arrow {
						display: none;
					}

					&:hover {
						background-color: $c-very-dark-water-green;
					}
				}

				.action {
					width: 100%;
					padding-top: 3rem;

					.title-action {
						width: 100%;
						font-family: oswald-regular;
						font-size: 1.5rem;
						line-height: 2rem;
						text-transform: uppercase;
						text-align: center;
						margin-bottom: 2rem;
						padding: 1rem 0;
						background-color: $c-blood-cherry;
						color: $c-very-white;
					}

					.action-content {
						width: 80%;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: space-evenly;
						margin: 0 auto;
						padding: 3rem 0;

						.emmaus-img {
							width: 300px;
							margin-bottom: 1rem;


							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	//^-------------------------- RESERVATION -------------------------- //

	.reservation {
		height: auto;
		padding: 3rem 0;

		.title {
			width: 100%;
			font-family: bebas;
			text-transform: uppercase;
			font-size: 4rem;
			line-height: 5rem;
			margin-bottom: 3rem;
			text-shadow: 0px 2px 3px $c-dark-green;
			color: $c-very-white;
			text-align: right;

			.title-span {
				margin-right: 1rem;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;

			.informations {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-bottom: 1rem;

				.prices {
					width: 100%;
					
					.title-informations {
						text-align: center;
						padding: 1rem 0;
						background-color: $c-green;
						font-family: oswald-regular;
						font-size: 1.5rem;
						line-height: 2rem;
						text-transform: uppercase;
						color: $c-white;
					}
	
					.text {
						width: 95%;
						font-family: oswald-regular;
						text-transform: uppercase;
						font-size: 1.2rem;
						line-height: 1.5rem;
						padding: 1rem;
						margin: 0 auto;
					}
				}
			}
			.nota-bene {
				width: 80%;
				font-family: montserrat-regular;
				font-style: italic;
				font-size: 1rem;
				line-height: 1.2rem;
				text-align: center;
			}

			.menu-btn {
				background-color: $c-dark-water-green;
				margin-top: 3rem;

				.fa-arrow {
					display: none;
				}

				&:hover {
					background-color: $c-very-dark-water-green;
				}
			}

			//& --------------------------- FORMLAIRE -------------------------------

			.title-form {
				width: 100%;
				text-align: center;
				padding: 1rem 0;
				background-color: $c-green;
				font-family: oswald-regular;
				font-size: 1.5rem;
				line-height: 2rem;
				text-transform: uppercase;
				color: $c-white;
				margin: 3rem 0 3rem 0;
			}

			.date-reservation {
				font-family: montserrat-regular;
				font-size: 1rem;
				text-transform: none;
			}

			//& --------------------------- MAP -------------------------------

			.title-map {
				width: 100%;
				text-align: center;
				padding: 1rem 0;
				background-color: $c-green;
				font-family: oswald-regular;
				font-size: 1.5rem;
				line-height: 2rem;
				text-transform: uppercase;
				color: $c-white;
				margin-top: 3rem;
			}

			.address {
				font-size: 1.2rem;
				line-height: 1.5rem;
				text-align: center;
				font-family: montserrat-regular;
				margin-top: 2rem;
			}

			.map-container {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 2rem 0;

				.map {
					width: auto;
					flex-shrink: 0;
					flex-grow: 1;
					height: 300px;
				}

				 .route-map {
					height: 300px;
				 }
			}
		}
	}

	//^-------------------------- CONTACT -------------------------- //

	.contact {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: left;
		padding: 3rem 0;


		.title {
			width: 95%;
			font-family: bebas;
			text-transform: uppercase;
			font-size: 4rem;
			line-height: 5rem;
			margin-bottom: 3rem;
			text-shadow: 0px 2px 3px $c-dark-green;
			color: $c-very-white;

			.title-span {
				margin-left: 1rem;
			}
		}

		.text {
			width: 95%;
			font-size: 1.2rem;
			line-height: 1.5rem;
			margin: 0 auto;
			white-space: pre-wrap;
			text-align: center;
			font-family: montserrat-regular;
			
			
			p {
				width: 100%;
				white-space: pre-wrap;
				margin: 0 auto 1rem auto;
				display: flex;
				flex-direction: column;

				& > .span-title {
					white-space: nowrap;
				}

				.title-contact {
					font-family: oswald-semibold;
				}
			}
		}
	}
}

//^--------------------------------- MOBILE -------------------------------------


@media (min-width: 375px) {

	.home-container {

		//^--------------------------- HERO -------------------------- //

		.hero {
			.content {

				.text-container {
					width: 80%;
					top: 3rem;
					left: 10%;
				}

				.image-container {

					.logo {
						left: 15%;
					}
	
					.citation {
						left: -28%;
					}
				}
			}
		}

		//^------------------------- ACTUALITY ------------------------- //

		//^-------------------------- AGENDA -------------------------- //

		//^-------------------------- RESERVATION -------------------------- //

		.reservation {
			.content {
	
				//& --------------------------- FORMLAIRE -------------------------------
	
				//& --------------------------- MAP -------------------------------
	
				.map-container {
					.map {
						height: 350px;
					}
	
					 .route-map {
						height: 350px;
					 }
				}
			}
		}
	}
}

@media (min-width: 414px) {

	.home-container {

		//^--------------------------- HERO -------------------------- //

		.hero {
			.content {
				.image-container {
					left: 18%;

					.logo {
						width: 220px;
						left: 11%;
					}

					.citation {
						left: -27%;
					}
				}
			}
		}

		//^------------------------- ACTUALITY ------------------------- //

		//^-------------------------- AGENDA -------------------------- //

		//^-------------------------- RESERVATION -------------------------- //

		.reservation {
			.content {
	
				//& --------------------------- FORMLAIRE -------------------------------
	
				//& --------------------------- MAP -------------------------------
	
				.map-container {
					.map {
						height: 380px;
					}
	
					 .route-map {
						height: 380px;
					}
				}
			}
		}
	}
}

//^--------------------------------- TABLET -------------------------------------


@media (min-width: 599px) {

	.home-container {

		//^--------------------------- HERO -------------------------- //

		.hero {
			.content {
				.text-container {
					.title {
						font-size: 5rem;
					}
	
					.subtitle {
						font-size: 2rem;
					}
	
					.subsubtitle {
						font-size: 1.5rem;
						line-height: 1.8rem;
					}
	
					.event {
						font-size: 1.3rem;
						line-height: 1.7rem;
					}
				}

				.image-container {
					width: 60%;
					height: 45vh;
					left: 19%;
					bottom: 5rem;
	
					.logo {
						width: 400px;
						left: 8%;
					}
	
					.citation {
						width: 85vw;
						left: -20%;
						font-size: 0.7rem;
					}
				}
			}

			.fa-arrow {
				bottom: 0.5rem;
				font-size: 1.2rem;
			}
	
			.arrow-up {
				margin-bottom: 0.5rem;
			}
		}

		//^------------------------- ACTUALITY ------------------------- //

		.actuality {
			padding: 5rem 0 3rem 0;

			.container {

				
				.image {
					width: 450px;
				}

				.sub-image {
					width: 150px;
					top: -1rem;
					right: -4rem;
				}
	
				.content {
					.title {
						width: 50%;
						font-size: 6rem;
						line-height: 7rem;
						align-self: self-end;
	
						.title-span {
							margin-right: 1rem;
						}
					}
	
					.text-container {
						.text {
							font-size: 1.5rem;
							line-height: 2rem;
						}
	
						.infos {
							font-size: 2rem;
							line-height: 2.5rem;
						}
					}
				}
			}
	
			.actuality-btn {
				margin: 1rem 2rem 2rem auto;
			}
		}

		//^-------------------------- AGENDA -------------------------- //

		.agenda {
			padding: 3rem 0;

			.content {
				.title {
					font-size: 6rem;
					line-height: 7rem;
				}
	
				.text-container {
					.text {
						font-size: 1.5rem;
						line-height: 2rem;
					}
	
					.program {
						.title-program {
							font-size: 2rem;
							line-height: 2.5rem;
						}
	
						.program-content {
							.show-infos {

								.image-show {
									width: 450px;
									margin-bottom: 2rem;
								}
	
								.text-show {
									width: 95%;
	
									.date {
										font-size: 1.5rem;
									}
	
									.title-show {
										font-size: 2.5rem;
										line-height: 3rem;
									}
	
									.details {
										font-size: 1.7rem;
										line-height: 1.9rem;
									}
								}
							}
						}
					}
	
					.action {
						.title-action {
							font-size: 2rem;
							line-height: 2.5rem;
						}
	
						.action-content {
							width: 80%;
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							justify-content: space-evenly;
							margin: 0 auto;
							padding: 3rem 0;
	
							.emmaus-img {
								width: 450px;
								margin-bottom: 2rem;
							}
						}
					}
				}
			}
		}

		//^-------------------------- RESERVATION -------------------------- //

		.reservation {
			height: auto;
			padding: 3rem 0;
	
			.title {
				font-size: 6rem;
				line-height: 7rem;
			}
	
			.content {
				.informations {
					.prices {
						.title-informations {
							font-size: 2rem;
							line-height: 2.5rem;
						}
		
						.text {
							font-size: 1.5rem;
							line-height: 2rem;
							padding: 2rem;
						}
					}
				}
				.nota-bene {
					font-size: 1rem;
					line-height: 1.2rem;
				}
	
				//& --------------------------- FORMLAIRE -------------------------------
	
				.title-form {
					font-size: 2rem;
					line-height: 2.5rem;
				}
	
				//& --------------------------- MAP -------------------------------
	
				.title-map {
					font-size: 2rem;
					line-height: 2.5rem;
				}
	
				.address {
					font-size: 1.5rem;
					line-height: 2rem;
				}
	
				.map-container {
	
					.map {
						height: 300px;
					}
	
					.route-map {
						height: 300px;
					}
				}
			}
		}

		//^-------------------------- CONTACT -------------------------- //

		.contact {
			padding: 3rem 0;
	
			.title {
				font-size: 6rem;
				line-height: 7rem;
			}
	
			.text {
				font-size: 1.5rem;
				line-height: 2rem;
				
				p {
					flex-direction: row;
					flex-wrap: wrap;
				}
			}
		}
	}
}

@media (min-width: 899px) {

	.home-container {

		//^--------------------------- HERO -------------------------- //

		.hero {
			.content {
				.text-container {
					.title {
						font-size: 7rem;
					}
	
					.subtitle {
						font-size: 3.5rem;
					}
	
					.subsubtitle {
						font-size: 2rem;
						line-height: 2.5rem;
					}
	
					.event {
						font-size: 1.7rem;
						line-height: 2.2rem;
					}
				}

				.image-container {
					.logo {
						width: 550px;
					}
	
					.citation {
						font-size: 1rem;
					}
				}
			}

			.fa-arrow {
				bottom: 0.5rem;
				font-size: 1.4rem;
			}
	
			.arrow-up {
				margin-bottom: 0.5rem;
			}
		}

		//^------------------------- ACTUALITY ------------------------- //

		//^-------------------------- AGENDA -------------------------- //

		//^-------------------------- RESERVATION -------------------------- //

	}
}

//^--------------------------------- DESKTOP -------------------------------------


@media (min-width: 1199px) {
	.home-container {
	
		//^--------------------------- HERO -------------------------- //
	
		.hero {
			.content {
				height: 100%;
				margin: 0 auto;
				text-align: left;
	
				.text-container {
					width: 50%;
					position: absolute;
					top: 5rem;
					left: 3rem;
				
	
					.title {
						font-size: 8rem;
						margin-top: 1.5rem;
					}
	
					.subtitle {
						font-size: 2.5rem;
						margin-bottom: 0.5rem;
					}
	
					.subsubtitle {
						font-size: 1.5rem;
						line-height: 2rem;
					}
	
					.event {
						font-size: 1.5rem;
						line-height: 2rem;
					}
				}
	
				.image-container {
					width: 10%;
					height: 80%;
					position: absolute;
					bottom: 6rem;
					left: 40%;
	
					.logo {
						width: 500px;
						margin-top: 2rem;
						left: 20%;
					}
	
					.citation {
						width: 70%;
						bottom: -3rem;
						left: 6rem;
						font-size: 0.8rem;
						text-align: center;
					}
				}
			}
	
			.fa-arrow {
				left: 49%;
				bottom: 1rem;
				font-size: 1.2rem;
			}
	
			.arrow-up {
				margin-bottom: 0.5rem;
			}
		}
	
		//^------------------------- ACTUALITY ------------------------- //

		.actuality {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding: 7rem 0 5rem 0;
	
			.container {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
	
				.image {
					width: 350px;
					margin: 10rem 0 0 2rem;
				}

				.sub-image {
					top: 7rem;
					right: -4rem;
				}
	
				.content {
					width: 60%;
	
					.title {
						font-size: 8rem;
						line-height: 9rem;
						margin: 0 10rem 3rem 0;
	
						.title-span {
							margin-right: -8rem;
						}
					}
				}
			}

			.reservation-btn-actuality {
				margin: 3rem 2rem 2rem auto;
			}

		}
	
		//^-------------------------- AGENDA -------------------------- //
	
		.agenda {
			padding: 5rem 0;

			.content {
				.title {
					font-size: 8rem;
					line-height: 9rem;
				}
	
				.text-container {
					.text {
						font-size: 2rem;
						line-height: 2.5rem;
					}
	
					.program {
						.title-program {
							font-size: 3rem;
						}
	
						.program-content {
							.show-infos {
								width: 80%;
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
								flex-wrap: wrap;
								margin: 3rem auto;
	
								.image-show {
									width: 350px;
								}
	
								.text-show {
									width: 500px;
	
									.date {
										font-size: 1.7rem;
										margin-bottom: 1rem;
									}
	
									.title-show {
										font-size: 3rem;
										line-height: 3rem;
										margin-bottom: 2rem;
									}
	
									.details {
										font-size: 1.5rem;
										line-height: 2rem;
									}
								}

								&:nth-child(2n) {
									flex-direction: row;
									align-content: center;
								}
	
	
								&::after {
									display: none;
								}
							}
						}
					}
	
					.action {
						margin-top: 5rem;
						padding: 3rem 0;
	
						.title-action {
							font-size: 3rem;
							margin-bottom: 2rem;
						}
	
						.action-content {
							width: 80%;
							padding: 3rem 0;
	
							.emmaus-img {
								width: 350px;
							}
						}
					}
				}
			}
		}
	
		//^-------------------------- RESERVATION -------------------------- //

		.reservation {
			padding: 5rem 0;

			.title {
				font-size: 8rem;
				line-height: 9rem;
			}
	
			.content {
				.informations {
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					.prices {
						width: 33.33333%;

						.title-informations {
							font-size: 3rem;
						}
					}
				}
				.nota-bene {
					font-size: 1.2rem;
					line-height: 1.5rem;
				}
	
				//& --------------------------- FORMLAIRE -------------------------------
	
				.title-form {
					font-size: 3rem;
				}
	
				//& --------------------------- MAP -------------------------------
	
				.title-map {
					font-size: 3rem;
				}
	
				.map-container {
					.map {
						height: 400px;
					}
	
					.route-map {
						height: 400px;
					}
				}
			}
		}

		//^-------------------------- CONTACT -------------------------- //

		.contact {
			width: 100%;
			text-align: left;
			padding: 5rem 0 10rem 0;
			margin-top: 48px;
	
			.title {
				font-size: 8rem;
				line-height: 9rem;
				margin-bottom: 3rem;
	
				.title-span {
					margin-left: 2rem;
				}
			}
	
			.text {
				line-height: 2rem;
			}
		}
	}
}

@media (min-width: 1399px) {

	.home-container {
	
		//^--------------------------- HERO -------------------------- //
	
		.hero {
			.content {
				.text-container {
					width: 45%;
					position: absolute;
					left: 6rem;
				}
	
				.image-container {
					width: 40%;
					height: 80vh;
					left: 45%;
				}
			}
		}
	
		//^------------------------- ACTUALITY ------------------------- //
	
		.actuality {
			.container {
				.content {
					.title {
						font-size: 9rem;
						line-height: 10rem;
					}
				}

				.image {
					width: 400px;
					margin: 10rem 0 0 3rem;
				}
			}
			.reservation-btn-actuality {
				margin: 2rem 2rem 2rem auto;
			}

		}
	
		//^-------------------------- AGENDA -------------------------- //

		.agenda {
			.content {
				.title {
					font-size: 9rem;
					line-height: 10rem;
				}
			}
		}
	
		//^-------------------------- RESERVATION -------------------------- //

		.reservation {
			.title {
				font-size: 9rem;
				line-height: 10rem;
			}
		}
	
		//^-------------------------- CONTACT -------------------------- //


		.contact {
			.title {
				font-size: 9rem;
				line-height: 10rem;
			}
		}
	}
}

@media (min-width: 1599px) {

	.home-container {
	
		//^--------------------------- HERO -------------------------- //
	
		.hero {
			.content {
				.text-container {
					width: 32%;
					top: 8rem;
					left: 6rem;
	
					.title {
						font-size: 10rem;
						line-height: 9rem;
						margin-top: 1.5rem;
					}
	
					.subtitle {
						font-size: 3rem;
						margin-bottom: 1rem;
					}
	
					.subsubtitle {
						font-size: 2rem;
						line-height: 2.2rem;
						white-space: nowrap;
					}
	
					.event {
						font-size: 1.7rem;
						line-height: 2.2rem;
					}
				}
	
				.image-container {
					left: 50%;

					.logo {
						width: 650px;
						left: 5%;
						bottom: 5%;
					}
	
					.citation {
						width: 100%;
						bottom: -2rem;
						left: -15%;
						font-size: 0.8rem;
	
						.italic {
							white-space: nowrap;
						}
					}
				}
			}
		}
	
		//^------------------------- ACTUALITY ------------------------- //

		.actuality {
			.container {
				.content {
					.title {
						margin: 0 6rem 3rem 0;
	
						.title-span {
							margin-right: -4rem;
						}
					}
				}

				.image {
					margin: 10rem 0 0 6rem;
				}
			}

			.reservation-btn-actuality {
				margin: 1rem 2rem 2rem auto;
			}
		}
	
		//^-------------------------- AGENDA -------------------------- //

	
		//^-------------------------- RESERVATION -------------------------- //

	
		//^-------------------------- CONTACT -------------------------- //

	}
}

@media (min-width: 1920px) {

	.home-container {
	
		//^--------------------------- HERO -------------------------- //
	
		.hero {
			.container {
				.content {
					.text-container {
						width: 45%;
						top: 10rem;
						left: 6rem;
		
						.title {
							font-size: 12rem;
							line-height: 11rem;
							margin-top: 1.5rem;
						}
		
						.subtitle {
							font-size: 3rem;
							margin-bottom: 1rem;
						}
		
						.subsubtitle {
							font-size: 2rem;
							line-height: 2.2rem;
							white-space: nowrap;
						}
		
						.event {
							font-size: 1.7rem;
							line-height: 2.2rem;
						}
					}
		
					.image-container {
						width: 45%;
						height: 85%;
						top: 5%;
						left: 50%;
	
						.logo {
							width: 780px;
							left: 5%;
							bottom: 5%;
						}
		
						.citation {
							bottom: -5%;
							left: -10%;
						}
					}
				}
			}
		}
	
		//^------------------------- ACTUALITY ------------------------- //
	
		.actuality {
			.container {
				.content {
					.title {
						margin: 0 0rem 3rem 0;
	
						.title-span {
							margin-right: 2rem;
						}
					}
				}

				.image {
					width: 450px;
					margin: 10rem 0 0 6rem;
				}
			}

			.reservation-btn-actuality {
				margin: -1rem 2rem 2rem auto;
			}
		}
	
		//^-------------------------- AGENDA -------------------------- //
	
		.agenda {
			.content {
				.text-container {
					.program {
						margin-top: 5rem;
	
						.program-content {
							.show-infos {
								width: 65%;

								.image-show {
									width: 400px;
								}
	
								.text-show {
									width: 650px;
								}
							}
						}
					}
	
					.action {
						.action-content {
							.emmaus-img {
								width: 500px;
							}
						}
					}
				}
			}
		}
	
		//^-------------------------- RESERVATION -------------------------- //


		//^-------------------------- CONTACT -------------------------- //
		.contact {
			padding-top: 0;
		}
	}
}
