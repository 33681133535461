.header-container {
	width: 100vw;
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	color: $c-white;
	background-color: #0126228c;
	backdrop-filter: blur(10px);
	position: fixed;
	top: 0;
	z-index: 10000;
	transition: all 0.3s ease-in;

	.logo-container {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		margin-left: 1rem;

		.logo-img {
			width: 45px;
		}
	}

	.contact-container {
		width: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.contact-list {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			color: $c-white;
			text-shadow: 0 0 0.5rem $c-dark-green;

			.email-ctn {
				position: relative;
				transition: all 1s ease-in-out;
				
				.fa-email {
					font-size: 1.3rem;
					margin-right: 0.5rem;

					&:hover {
						color: $c-cherry-red;
						transform: translateY(-3px);
					}

					&:hover:nth-child(2n+1) {
						opacity: 1;
					}
				}
				
				&:hover .span-mail {
					display: inline;
				}

				.span-mail {
					font-size: 0.9rem;
					font-family: montserrat-regular;
					display: none;
					background-color: $c-cherry-red;
					padding: 1rem;
					border-radius: 20px;
					position: absolute;
					top: 2.5rem;
					left: -7rem;
					transition: all 0.3s ease-in-out;

					&:after {
						content: '';
						position: absolute;
						top: -0.8rem;
						left: 37%;
						border-top: 20px solid $c-cherry-red;
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						transform: rotate(180deg);
					}
				}
			}

			.fa-facebook {
				font-size: 1.3rem;
			}

			.fa-instagram {
				font-size: 1.5rem;
				margin: -0.1rem 0 -0.2rem 0.5rem;
			}
		}
	}

	.burger-container {
		border: none;
		background-color:  transparent;
		margin-right: 0.5rem;
	}

	.burger {
		display: flex;
		width: 25px;
		height: 3px;
		background-color: $c-white;
    	border-radius: 5px;
		position: relative;
		z-index: 5000;

		&::before {
			content: '';
			width: 100%;
			height: 3px;
			background-color: $c-white;
			border-radius: 5px;
			position: absolute;
			bottom: 0.5rem;
			left: 0;
		}

		&::after {
			content: '';
			width: 100%;
			height: 3px;
			background-color: $c-white;
			border-radius: 5px;
			position: absolute;
			top: 0.5rem;
			right: 0;
		}
	}

	.cross {
		display: flex;
		width: 25px;
		height: 25px;
		font-size: 1.7rem;
		color: $c-white;
		display: flex;
		flex-direction: column;
		margin-top: -0.2rem;
	}

	.navigation-container {
		display: none;
	}

	.navigation-container-mobile {
		width: 100vw;
		height: calc(100vh - 50px);
		display: flex;
		justify-content: center;
		background-color: #012622f6;
		backdrop-filter: blur(10px);
		-webkit-backdrop-filter: blur(10px);
		position: absolute;
		top: 50px;
		left: 0;
		bottom: 0;
		right: 0;

		.nav-list {
			height: 75%;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			text-shadow: 0 1px 1px $c-dark-green;
			font-family: oswald-regular;
			text-transform: uppercase;
			font-size: 1.4rem;
			align-items: center;
			margin: auto;

			.nav-item {
				transition: transform 0.3s ease-in-out;
				margin-bottom: 1rem;
				position: relative;

				&:hover {
					color: $c-pink-cherry;
				}
			}

			.blog-link {
				background-color: $c-cherry-red;
				padding: 0.2rem 1rem;
				border-radius: 15px;
				color: $c-very-white;
				transition: all 0.3s ease-in-out;


				&:hover {
					box-shadow: 0px 10px 20px -10px $c-water-green;
					transform: scale(1.02);
					font-size: 1.5rem;
					background-color: $c-pink-cherry;
				}

				&:active {
					box-shadow: 0px 10px 10px -10px $c-water-green;
					transform: scale(1);
					background-color: $c-cherry-red;
				}

				&::after {
					display: none;
				}

				& > .active::after {
					display: none;
				}
			}

			.blog-link-active {
				background-color: $c-pink-cherry;
				padding: 0.2rem 1rem;
				border-radius: 15px;
				color: $c-very-white;
				box-shadow: 0px 10px 20px -10px $c-water-green;
			}
		}
	}
}

.header-hidden {
	width: 100vw;
	opacity: 0;
}

.header-display {
	width: 100vw;
	opacity: 1;
}


//^--------------------------------- TABLET -------------------------------------

@media (min-width: 599px) {

	.header-container {
		height: 70px;
	
		.logo-container {
	
			.logo-img {
				width: 55px;
			}
		}
	
		.contact-container {
			.contact-list {
				.email-ctn {
					.fa-email {
						font-size: 1.5rem;
					}
	
					.span-mail {
						font-size: 1rem;
						padding: 1rem 2rem;
						left: -10rem;
	
						&:after {
							left: 45%;
						}
					}
				}
	
				.fa-facebook {
					font-size: 1.5rem;
				}
	
				.fa-instagram {
					font-size: 1.7rem;
				}
			}
		}
	
	
		.burger {
			width: 35px;
			height: 4px;
			border-radius: 5px;
	
			&::before {
				height: 4px;
				bottom: 0.7rem;
			}
	
			&::after {
				height: 4px;
				top: 0.7rem;
			}
		}
	
		.cross {
			width: 35px;
			height: 35px;
			font-size: 2.2rem;
		}
	
	
		.navigation-container-mobile {
			height: calc(100vh - 70px);
			// -webkit-backdrop-filter: blur(10px);
			top: 70px;
	
			.nav-list {
				height: 55%;
				font-size: 1.4rem;
			}
		}
	}
}


@media (min-width: 899px) {

	.header-container {
		height: 80px;
	
		.logo-container {
	
			.logo-img {
				width: 65px;
			}
		}
	
		.contact-container {
			.contact-list {
				.email-ctn {
					.fa-email {
						font-size: 1.7rem;
						margin-right: 1rem;
					}
	
					.span-mail {
						font-size: 1.1rem;
						padding: 1rem 2rem;
						left: -12rem;
	
						&:after {
							left: 45%;
						}
					}
				}
	
				.fa-facebook {
					font-size: 1.7rem;
				}
	
				.fa-instagram {
					font-size: 1.9rem;
					margin-left: 1rem;
				}
			}
		}

		.burger-container {
			margin-right: 1rem;
		}
	
		.cross {
			font-size: 2.4rem;
			margin-top: -0.5rem;
		}
	
		.navigation-container-mobile {
			height: calc(100vh - 80px);
			top: 80px;
	
			.nav-list {
				height: 50%;
				font-size: 1.7rem;
			}
		}
	}
}

//^--------------------------------- DESKTOP -------------------------------------

@media (min-width: 1199px) {
	.header-container {
		.logo-container {
			.logo-img {
				width: 75px;
			}
		}
	
		.contact-container {
			.contact-list {
				.email-ctn {
					.span-mail {
						left: -11rem;
	
						&:after {
							left: 46%;
						}
					}
				}
			}
		}
	
		.burger-container {
			display: none;
		}
	
		.navigation-container {
			width: 780px;
			display: flex;
			flex-direction: column;
			justify-content: center;
	
			.nav-list {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				text-shadow: 0 1px 1px $c-dark-green;
				font-family: oswald-regular;
				text-transform: uppercase;
				font-size: 1.4rem;
				align-items: center;
	
				.nav-item {
					transition: all 0.3s ease-in-out;
					position: relative;
	
					&:hover {
						color: $c-pink-cherry;
					}
				}
	
				.blog-link {
					background-color: $c-cherry-red;
					padding: 0.2rem 1rem;
					border-radius: 15px;
					color: $c-very-white;
					transition: all 0.3s ease-in-out;
	
	
					&:hover {
						box-shadow: 0px 10px 20px -10px $c-water-green;
						transform: scale(1.02);
						font-size: 1.5rem;
						background-color: $c-pink-cherry;
					}
	
					&:active {
						box-shadow: 0px 10px 10px -10px $c-water-green;
						transform: scale(1);
						background-color: $c-cherry-red;
					}
	
					&::after {
						display: none;
					}
	
					& > .active::after {
						display: none;
					}
				}
	
				.blog-link-active {
					background-color: $c-pink-cherry;
					padding: 0.2rem 1rem;
					border-radius: 15px;
					color: $c-very-white;
					box-shadow: 0px 10px 20px -10px $c-water-green;
				}
			}
		}
	}
}

@media (min-width: 1920px) {
	.header-container {
		width: 100vw;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background-color: transparent;
		color: $c-white;
		background-color: #0126228c;
		backdrop-filter: blur(10px);
		position: fixed;
		top: 0;
		z-index: 10000;
		transition: all 0.3s ease-in;

		.logo-container {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			margin-left: 1rem;

			.logo-img {
				width: 75px;
			}
		}

		.contact-container {
			.contact-list {
				.email-ctn {
					.fa-email {
						font-size: 1.7rem;
						margin-right: 1rem;
					}
				}

				.fa-instagram {
					margin: -0.1rem 0 0 1rem;
				}
			}
		}


		.navigation-container {
			// width: 1100px; //^ avec "qui sommes-nous" et "faire un don"
			width: 850px;
		}
	}
}
