.blog-container {
	min-width: 100%;
	max-width: 100vw;
	height: 100%;
	padding: 5rem 0 0 0;
	overflow-x: hidden;
	position: relative;

	.title {
		font-family: bebas;
		text-transform: uppercase;
		font-size: 3rem;
		line-height: 4rem;
		margin-bottom: 5rem;
		text-shadow: 0px 2px 3px $c-dark-green;
		color: $c-very-white;

		.title-span {
			margin-left: 1rem;
		}
	}

	.text {
		font-family: montserrat-regular;
		font-size: 1rem;
		text-align: center;
		margin: 5rem auto;
	}
}

@media (min-width: 599px) {

	.blog-container {
		.title {
			font-size: 6rem;
			line-height: 7rem;
		}

		.text {
			font-size: 1.5rem;
			margin: 10rem auto;
		}
	}
}

@media (min-width: 899px) {

	.blog-container {
		.text {
			font-size: 2rem;
			margin: 20rem auto;
		}
	}
}


@media (min-width: 1199px) {

	.blog-container {
		.title {
			font-size: 8rem;
			line-height: 9rem;

			.title-span {
				margin-left: 2rem;
			}
		}
	}
}

@media (min-width: 1399px) {

	.blog-container {
		.title {
			font-size: 9rem;
			line-height: 10rem;
		}
	}
}
