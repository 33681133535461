@keyframes slidedown {
	0% {
		transform: translateY(-30%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideright {
	0% {
		transform: translateX(-30%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes changeBg {
	0% {
		background: none;
		background-position: center center;
	}
	1% {
		background: none;
		background-position: center center;
	}
	1.5% {
		background-image: url('../../images/lights.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;  //& pour régler le problème sur iPhone
	}
	2% {
		background: none;
		background-position: center center;
	}
	2.5% {
		background-image: url('../../images/lights.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	3% {
		background: none;
		background-position: center center;
	}
	4% {
		background: none;
		background-position: center center;
	}
	4.5% {
		background-image: url('../../images/lights.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	5% {
		background: none;
		background-position: center center;
	}
	9% {
		background: none;
		background-position: center center;
	}
	10% {
		background-image: url('../../images/lights.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	100% {
		background: none;
		background-position: center center;
	}
}

@keyframes move {
	0% {
		border-radius: 14% 16% 12% 24% / 13% 12% 8% 17%;
	}
	25% {
		border-radius: 18% 8% 14% 26% / 13% 11% 15% 8%;
	}
	50% {
		border-radius: 8% 12% 21% 15% / 11% 12% 24% 9%;
	}
	75% {
		border-radius: 15% 9% 19% 11% / 9% 15% 10% 15%;
	}
	100% {
		border-radius: 4% 16% 16% 14% / 13% 8% 12% 17%;
	}
}

@keyframes slide {

	0% {
	transform: translateY(-30%);
	}

	100% {
	transform: translateY(0%);
	}
}

@keyframes preloader {
	100% { transform: scale(2); }
}

@keyframes balance {
	0% {
			transform: rotateZ(-5deg);
	}
	25% {
			transform: rotateZ(5deg);
	}
	50% {
			transform: rotateZ(-5deg);
	}
	75% {
			transform: rotateZ(5deg);
	}
	100% {
			transform: rotateZ(-5deg);
	}
}

@media (min-width: 1399px) {

	@keyframes move {
		0% {
			border-radius: 34% 16% 32% 44% / 33% 18% 22% 37%;
		}
		25% {
			border-radius: 48% 18% 24% 26% / 21% 21% 39% 39%;
		}
		50% {
			border-radius: 28% 12% 35% 35% / 21% 12% 38% 29%;
		}
		75% {
			border-radius: 35% 15% 39% 11% / 29% 20% 30% 31%;
		}
		100% {
			border-radius: 34% 16% 36% 34% / 33% 18% 22% 37%;
		}
	}
}

@media (min-width: 1920px) {
	@keyframes changeBg {
		0% {
			background: none;
		}
		1% {
			background: none;
		}
		1.5% {
			background-image: url('../../images/lights.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
		2% {
			background: none;
		}
		2.5% {
			background-image: url('../../images/lights.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
		3% {
			background: none;
		}
		4% {
			background: none;
		}
		4.5% {
			background-image: url('../../images/lights.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
		5% {
			background: none;
		}
		9% {
			background: none;
		}
		10% {
			background-image: url('../../images/lights.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
		80% {
			background-image: url('../../images/lights.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
		100% {
			background: none;
		}
	}
}
