html,
body {
	width: 100%;
	height: 100%;
	color: $c-light-water-green;

	&:after {
		width: 102%;
		height: 100%;
		content: '';
		display: block;
		background-image: url('../../images/mobile-wallpaper.png');
		background-repeat: no-repeat;
		background-size: cover;
		position: fixed;
		top: 0;
		left: -5px;
		z-index: -1;
	}

	@media (min-width: 1199px) {
		&:after {
			width: 105%;
			background-image: url('../../images/wallpaper.svg');
			left: -20px;
		}
	}
}

::selection {
	background-color: $c-cherry-red;
	color: $c-very-white;
}

.bg-red {
	background-color: $c-cherry-red;
	padding: 0.5rem 1rem 0 1rem;
}

.bg-red-archives {
	background-color: $c-cherry-red;
	padding: 0 1rem 0 0.5rem;
}

.extra {
	margin-right: 1rem;
}

.bold {
	font-family: montserrat-bold;
	color: $c-white;
}

.underline {
	text-decoration: underline;
}

.uppercase {
	text-transform: uppercase;
}

.phone {
	pointer-events: auto;
	white-space: nowrap;
}

.fa {
	transition: all 0.3s ease-in-out;
}

.fa:hover {
	color: $c-cherry-red;
	transform: translateY(-3px);
}

@media (min-width: 375px) {
	.extra {
		margin-right: 0rem;
	}
}

@media (min-width: 1199px) {

	.bg-red {
		padding: 1rem 2rem 0 2rem;
	}

	.bg-red-archives {
		background-color: $c-cherry-red;
		padding: 0 2rem 0 1rem;
	}
}