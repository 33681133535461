.hero-landscape-568 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 1rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 2rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 80%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 180px;
                position: absolute;
                bottom: 10%;
                left: 15%;
            }

            .citation {
                width: 55vw;
                position: absolute;
                bottom: -10%;
                left: -35%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-640 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 1rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 2.5rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 80%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 180px;
                position: absolute;
                bottom: 10%;
                left: 15%;
            }

            .citation {
                width: 55vw;
                position: absolute;
                bottom: -10%;
                left: -35%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-667 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 2rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 2.5rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 80%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 180px;
                position: absolute;
                bottom: 10%;
                left: 15%;
            }

            .citation {
                width: 95vw;
                position: absolute;
                bottom: -10%;
                left: -90%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-700 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 2rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 3rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 80%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 180px;
                position: absolute;
                bottom: 10%;
                left: 15%;
            }

            .citation {
                width: 95vw;
                position: absolute;
                bottom: -10%;
                left: -90%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-736 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 2rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 3rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1.5rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 80%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 180px;
                position: absolute;
                bottom: 10%;
                left: 15%;
            }

            .citation {
                width: 95vw;
                position: absolute;
                bottom: -5%;
                left: -90%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-740 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 2rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 2.5rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 80%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 180px;
                position: absolute;
                bottom: 10%;
                left: 15%;
            }

            .citation {
                width: 95vw;
                position: absolute;
                bottom: -10%;
                left: -90%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-812 {
	width: 100%;
	height: 100vh;
	background-image: url('../../images/green-background.svg');
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;

	.curtain-left,
	.curtain-right {
		height: 100vh;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 0;
	}

	.curtain-left {
		left: 0;
	}

	.curtain-right {
		right: 0;
	}

	.content {
		height: 100%;
		margin: 0 auto;
		text-align: left;


		.text-container {
			width: 50%;
			color: $c-very-white;
			position: absolute;
			top: 2rem;
			left: 3rem;

			.title {
				font-family: bebas;
				font-size: 3rem;
				margin: 1rem 0;
			}

			.subtitle {
				font-family: bebas;
				font-size: 1.8rem;
				margin-bottom: 0.5rem;
			}

			.subsubtitle {
				font-family: oswald-regular;
				font-size: 1.3rem;
				line-height: 1.5rem;
			}

			.event {
				font-family: oswald-light;
				font-size: 1rem;
				line-height: 1.2rem;

				.next-event {
					font-family: 'oswald-semibold';
				}
			}
		}

		.image-container {
			width: 40%;
			height: 80%;
			position: absolute;
			bottom: 4rem;
			left: 50%;
			animation: changeBg 10s infinite;

			.logo {
				width: 180px;
				position: absolute;
				bottom: 10%;
				left: 15%;
			}

			.citation {
				width: 95vw;
				position: absolute;
				bottom: -10%;
				left: -30%;
				color: $c-white;
				font-family: montserrat-light;
				font-size: 0.6rem;

				.italic {
					width: 95vw;
					font-style: italic;
				}
			}
		}
	}

	.fa-arrow {
		position: absolute;
		left: 48%;
		bottom: 0.3rem;
		font-size: 0.9rem;
		animation: slidedown 1.3s ease-in-out 0s infinite running;
	}

	.arrow-up {
		margin-bottom: 0.4rem;
	}
}

.hero-landscape-844 {
	width: 100%;
	height: 100vh;
	background-image: url('../../images/green-background.svg');
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;

	.curtain-left,
	.curtain-right {
		height: 100vh;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 0;
	}

	.curtain-left {
		left: 0;
	}

	.curtain-right {
		right: 0;
	}

	.content {
		height: 100%;
		margin: 0 auto;
		text-align: left;


		.text-container {
			width: 50%;
			color: $c-very-white;
			position: absolute;
			top: 2rem;
			left: 3rem;

			.title {
				font-family: bebas;
				font-size: 3rem;
				margin: 1rem 0;
			}

			.subtitle {
				font-family: bebas;
				font-size: 1.8rem;
				margin-bottom: 0.5rem;
			}

			.subsubtitle {
				font-family: oswald-regular;
				font-size: 1.3rem;
				line-height: 1.5rem;
			}

			.event {
				font-family: oswald-light;
				font-size: 1rem;
				line-height: 1.2rem;

				.next-event {
					font-family: 'oswald-semibold';
				}
			}
		}

		.image-container {
			width: 40%;
			height: 80%;
			position: absolute;
			bottom: 4rem;
			left: 50%;
			animation: changeBg 10s infinite;

			.logo {
				width: 180px;
				position: absolute;
				bottom: 6%;
				left: 23%;
			}

			.citation {
				width: 95vw;
				position: absolute;
				bottom: -10%;
				left: -30%;
				color: $c-white;
				font-family: montserrat-light;
				font-size: 0.6rem;

				.italic {
					width: 95vw;
					font-style: italic;
				}
			}
		}
	}

	.fa-arrow {
		position: absolute;
		left: 48%;
		bottom: 0.3rem;
		font-size: 0.9rem;
		animation: slidedown 1.3s ease-in-out 0s infinite running;
	}

	.arrow-up {
		margin-bottom: 0.4rem;
	}
}

.hero-landscape-851 {
	width: 100%;
	height: 100vh;
	background-image: url('../../images/green-background.svg');
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;

	.curtain-left,
	.curtain-right {
		height: 100vh;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 0;
	}

	.curtain-left {
		left: 0;
	}

	.curtain-right {
		right: 0;
	}

	.content {
		height: 100%;
		margin: 0 auto;
		text-align: left;


		.text-container {
			width: 50%;
			color: $c-very-white;
			position: absolute;
			top: 2rem;
			left: 3rem;

			.title {
				font-family: bebas;
				font-size: 3rem;
				margin: 1rem 0;
			}

			.subtitle {
				font-family: bebas;
				font-size: 1.8rem;
				margin-bottom: 0.5rem;
			}

			.subsubtitle {
				font-family: oswald-regular;
				font-size: 1.3rem;
				line-height: 1.5rem;
			}

			.event {
				font-family: oswald-light;
				font-size: 1rem;
				line-height: 1.2rem;

				.next-event {
					font-family: 'oswald-semibold';
				}
			}
		}

		.image-container {
			width: 40%;
			height: 80%;
			position: absolute;
			bottom: 4rem;
			left: 50%;
			animation: changeBg 10s infinite;

			.logo {
				width: 180px;
				position: absolute;
				bottom: 10%;
				left: 15%;
			}

			.citation {
				width: 95vw;
				position: absolute;
				bottom: -10%;
				left: -30%;
				color: $c-white;
				font-family: montserrat-light;
				font-size: 0.6rem;

				.italic {
					width: 95vw;
					font-style: italic;
				}
			}
		}
	}

	.fa-arrow {
		position: absolute;
		left: 48%;
		bottom: 0.3rem;
		font-size: 0.9rem;
		animation: slidedown 1.3s ease-in-out 0s infinite running;
	}

	.arrow-up {
		margin-bottom: 0.4rem;
	}
}

.hero-landscape-1024 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 10rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 5rem;
                margin: 1rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 1.5rem;
                line-height: 1.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1rem;
                line-height: 1.2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 40%;
            height: 90%;
            position: absolute;
            bottom: 4rem;
            left: 50%;
            animation: changeBg 10s infinite;

            .logo {
                width: 350px;
                position: absolute;
                bottom: 22%;
                left: 15%;
            }

            .citation {
                width: 95vw;
                position: absolute;
                bottom: 0%;
                left: -40%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

.hero-landscape-1366 {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/green-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    .curtain-left,
    .curtain-right {
        height: 100vh;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .curtain-left {
        left: 0;
    }

    .curtain-right {
        right: 0;
    }

    .content {
        height: 100%;
        margin: 0 auto;
        text-align: left;


        .text-container {
            width: 50%;
            color: $c-very-white;
            position: absolute;
            top: 10rem;
            left: 3rem;

            .title {
                font-family: bebas;
                font-size: 7rem;
                margin: 2rem 0;
            }

            .subtitle {
                font-family: bebas;
                font-size: 4rem;
                margin-bottom: 1rem;
            }

            .subsubtitle {
                font-family: oswald-regular;
                font-size: 2rem;
                line-height: 2.2rem;
            }

            .event {
                font-family: oswald-light;
                font-size: 1.5rem;
                line-height: 2rem;

                .next-event {
                    font-family: 'oswald-semibold';
                }
            }
        }

        .image-container {
            width: 45%;
            height: 90%;
            position: absolute;
            bottom: 4rem;
            left: 45%;
            animation: changeBg 10s infinite;

            .logo {
                width: 450px;
                position: absolute;
                bottom: 20%;
                left: 20%;
            }

            .citation {
                width: 95vw;
                position: absolute;
                bottom: 0%;
                left: -30%;
                color: $c-white;
                font-family: montserrat-light;
                font-size: 0.6rem;

                .italic {
                    width: 95vw;
                    font-style: italic;
                }
            }
        }
    }

    .fa-arrow {
        position: absolute;
        left: 48%;
        bottom: 0.3rem;
        font-size: 0.9rem;
        animation: slidedown 1.3s ease-in-out 0s infinite running;
    }

    .arrow-up {
        margin-bottom: 0.4rem;
    }
}

