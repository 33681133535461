.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 1rem;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #5f7763cf;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .image {
        width: 75%;
    }

    .cross {
        font-family: montserrat-semibold;
		font-size: 2rem;
		color: $c-light-water-green;
        position: absolute;
        top: 3rem;
        right: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        .text {
            font-size: 1rem;
            margin-right: 0.5rem;
        }
    }
}

.modal-landscape {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 1rem;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #5f7763cf;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: scroll;

    .image {
        height: 90%;
    }

    .cross {
        font-family: montserrat-semibold;
		font-size: 2rem;
		color: $c-light-water-green;
        position: absolute;
        top: 4rem;
        right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        .text {
            font-size: 1rem;
            margin-right: 0.5rem;
        }
    }
}

@media (min-width: 599px) {
    .modal {
        top: 2rem;

        .image {
            width: 60%;
        }

        .cross {
            font-size: 2.5rem;
            top: 4rem;

            .text {
                font-size: 1.5rem;
                margin-right: 1rem;
            }
        }
    }
}

@media (min-width: 1199px) {
    .modal {
        top: 3rem;

        .image {
            width: 40%;
        }

        .cross {
            font-size: 3rem;
        }
    }
}

@media (min-width: 1399px) {
    .modal {
        .image {
            width: 30%;
        }

        .cross {
            top: 5rem;
            right: 5rem;
        }
    }
}