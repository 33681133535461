.button {
	display: flex;
	flex-direction: row;
	font-family: oswald-bold;
	text-transform: uppercase;
	align-items: center;
	color: $c-very-white;
	background-color: $c-cherry-red;
	padding: 0.5rem 1.5rem;
	border-radius: 25px;
	transition: all 0.3s ease-in-out;
	margin: 1rem auto 2rem auto;
	

	.label {
		font-size: 1.2rem;
		text-shadow: 0 1px 2px $c-dark-green;
		margin-right: 1rem;
	}
}


@media (min-width: 599px) {
	.button {
		display: flex;
		flex-direction: row;
		font-family: oswald-bold;
		text-transform: uppercase;
		align-items: center;
		color: $c-very-white;
		background-color: $c-cherry-red;
		padding: 0.5rem 1.5rem;
		border-radius: 25px;
		transition: all 0.3s ease-in-out;
		margin: 2rem auto;
		
	
		.label {
			font-size: 1.5rem;
			text-shadow: 0 1px 2px $c-dark-green;
			margin-right: 1rem;
		}
	
		&:hover {
			box-shadow: 0px 10px 20px -10px $c-water-green;
			transform: scale(1.05);
			font-size: 1.5rem;
			background-color: $c-blood-cherry;
		}
	
		&:active {
			box-shadow: 0px 10px 10px -10px $c-water-green;
			transform: scale(1);
			background-color: $c-cherry-red;
		}
	}
}
