// https://paletton.com/#uid=15x0u0krIeTgLpQmrkaxOa0K85A
$c-very-dark-cherry: #2a0007;
$c-dark-cherry: #4b000c;
$c-blood-cherry: #700f1e;
$c-cherry-red: #982d3e;
$c-pink-cherry: #c25d6d;

// https://paletton.com/#uid=13f0u0kva7Gm1h9qzc4FP45RR1o
$c-very-dark-green: #000706;
$c-dark-green: #001412;
$c-green: #012622;
$c-light-green: #0a3c37;
$c-very-light-green: #1a554f;

//https://paletton.com/#uid=12S0u0k4cnN0TDI2psL6pjv95e+
$c-very-dark-water-green: #415b46;
$c-dark-water-green: #5f7763;
$c-water-green: #7e9181;
$c-light-water-green: #acb8af;
$c-white: #ced3cf;
$c-very-white: #f6f4f4;
