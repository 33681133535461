.loader-container {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0; 
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center; 
	justify-content: center;  
	z-index: 100000;

		.loader {
			width: 150px;
			animation: balance 2s infinite;
		}

		.dots-container {
			display: flex;
			flex-direction: row;
			margin: 3rem 0 0 1rem;

			.dots {
					border-radius: 50%;
					border: 5px solid $c-cherry-red;
					margin: 10px;
			}
				
			.dots:nth-child(1) {
				animation: preloader 0.7s ease-in-out alternate infinite;
			}
	
			.dots:nth-child(2) {
				animation: preloader 0.7s ease-in-out alternate .3s infinite;
			}
				
			.dots:nth-child(3) {
				animation: preloader 0.7s ease-in-out alternate .6s infinite;
			}
		}

}

@media (min-width: 899px) {

	.loader-container {

		.loader {
			width: 200px;
		}

		.dots-container {
			margin: 3rem 0 0 3rem;
		}
	}

}