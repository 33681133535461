.footer-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #0126228c;
	backdrop-filter: blur(10px);
    padding: 2rem;

    .content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        margin: 0 auto;
        align-content: center;

        .element {
            width: 600px;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo-footer {
                width: 150px;
            }

            .title-logo {
                font-family: bebas;
                color: $c-cherry-red;
                font-size: 1.5rem;
                margin: 0.5rem 0 1rem 0;
            }

            .text {
                font-family: montserrat-regular;
                text-align: center;
                font-size: 1rem;
                margin: 0;
            }
        }

        .social-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-family: oswald-semibold;
            text-transform: uppercase;
            margin: 2rem auto;

            .social {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                &::after {
                    content: "\2193";
                    font-size: 1.5rem;
                    margin-top: 1rem;
                    // animation: slide 0.5s infinite alternate;
                }
            }

            .icones {
                width: 100px;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                
                .fa-facebook, .fa-instagram {
                    margin-top: 1rem;
                    font-size: 2rem;
                    color: $c-very-white;

                    &:hover {
                        color: $c-cherry-red;
                    }
                }

            }
        }

        .links-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: montserrat-regular;
            
            .legales-link {
                font-size: 1rem;
                transition: all 0.3s ease-in-out;
    
                &:hover {
                    color: $c-very-white;
                    text-decoration: underline;
                }
            }
        }
    }
}

.fidia {
    font-size: 1rem;
    padding: 0.5rem 0;
    font-family: oswald-regular;
    text-align: center;
    background-color: $c-dark-green;
}

@media (min-width: 1199px) {

    .footer-container {
        .content {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
    
            .element {
                width: 33,3333333%;
            }
    
            .logo-container {
                width: 33,3333333%;
            }
    
            .social-container {
                width: 33,3333333%;
            }
        }
    }
}