.form-container {
	width: 100%;

	.form {
		width: auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;

		.label {
			display: flex;

			.star {
				font-size: 1rem;
				margin-bottom: 2rem;
			}
		}

		& > * {
			margin-bottom: 0.5rem;
		}

		.personnal-infos {
			width: 95%;
			display: flex;
			flex-direction: column;
			margin: 0 auto;
		}

		.input-container {
			width: 500px;
			display: flex;
			justify-content: center;
			margin-bottom: 1rem;

			& > .label {
				font-family: oswald-regular;
				font-size: 1.2rem;
				text-transform: uppercase;
				color: $c-white;
			}

			.input-content {
				width: 100%;

				& > input {
					width: auto;
					height: 35px;
					border-radius: 10px;
					border: none;
					padding: 0 0.5rem;
					font-family: montserrat-regular;
					font-size: 1.2rem;
					color: $c-dark-green;
					box-shadow: inset 0px 10px 7px -10px $c-very-dark-green;
					margin: -0.5rem 0 0 0.5rem;
					background-color: $c-white;
				}

				.firstname-input {
					width: 200px;
				}

				.phone-input {
					width: 180px;
				}

				.email-input {
					width: 220px;
				}


				.error-message {
					width: 100%;
					font-family: oswald-regular;
					color: $c-pink-cherry;
					margin-top: 0.5rem;
				}
			}
		}

		.span {
			width: 95%;
			font-family: montserrat-thin-italic;
			margin: 1rem auto 0 auto;
		}

		.legend {
			width: 95%;
			font: {
				family: montserrat-regular;
				size: 1.2rem;
			}
			line-height: 1.5rem;
			margin: 1rem auto 3rem auto;
		}

		//^----------------------------- table -------------------------------

		.table-container {
			width: auto;
			color: $c-white;
			background: linear-gradient(217deg, $c-blood-cherry, #ffffff00 70.71%),
			linear-gradient(127deg, $c-dark-cherry, #ffffff00 70.71%),
			linear-gradient(336deg, $c-blood-cherry, #ffffff00 70.71%);
			padding-top: 3rem;
			box-shadow: $c-very-dark-green 0px 5px 30px -30px, $c-very-dark-green 0px 20px 30px -15px;
			animation: move 10s infinite alternate;

			.table {
				width: auto;
				padding: 1rem;
				overflow-x: scroll;
			}
			
			table {
				width: 1000px;
			}

			.empty {
				width: 200px;
				background-color: transparent;
			}

			thead {
				tr {
					th {
						width: 280px;
						font-size: 1.2rem;
						font-family: oswald-semibold;
						text-transform: uppercase;
						padding: 1rem 0;
						background-color: $c-white;
						border-radius: 10px;
						color: $c-blood-cherry;
					}
				}
			}

			td {
				width: 150px;
				font-family: montserrat-regular;
				text-align: center;
				padding: 1rem 0;

				.input { // inputs nombre places spectacle
					width: 70px;
					text-align: center;
					border-radius: 10px;
					padding: 0.2rem 0;
					font-size: 1.2rem;
					color: $c-dark-green;
					box-shadow: inset 0px 10px 7px -10px $c-very-dark-green;
					background-color: $c-white;
				}
			}

			.show-name {  
				width: 300px;
				margin-top: -0.5rem;
			}

			.name-show-span {
				font-family: oswald-extra-light;
				color: $c-pink-cherry;
			}

			tbody {
				tr {
					.head {
						font-size: 1.1rem;
						font-family: oswald-semibold;
						text-transform: uppercase;
						padding: 1rem 0;
						background-color: #ced3cf31;
						border-radius: 10px;
					}
				}
			}

			//^------------------------------ BUTTON ---------------------------------

			.form-button {
				background-color: $c-white;
				color: $c-dark-cherry;
				box-shadow: 0px 10px 10px -10px $c-dark-green;
				margin-bottom: 0;
			
				.label {
					text-shadow: 0 0 0rem $c-dark-green;
				}
			
				&:hover {
					box-shadow: 0px 10px 20px -10px $c-dark-green;
					background-color: $c-very-white;
				}
			
				&:active {
					box-shadow: 0px 10px 10px -10px $c-dark-green;
				}
			}

			//^------------------------------ MESSAGE ---------------------------------


			.status-confirmation-message {
				width: 95%;
				text-align: center;
				margin: 1rem auto 0 auto;
				font-family: oswald-semibold;
				color: $c-white;
				font-size: 1.2rem;
				line-height: 1.5rem;
				padding-left: 0;
			}

			.payment-infos {
				width: 95%;
				text-align: center;
				margin: 1rem auto 0 auto;
				font-family: oswald-regular;
				color: $c-white;
				font-size: 1rem;
				line-height: 1.2rem;
				padding-bottom: 2rem;
				padding-left: 0;
			}
		}
	}
}

@media (min-width: 375px) {
	.form-container {
		.form {
			.input-container {
				.input-content {
					.lastname-input {
						width: 300px;
					}

					.firstname-input {
						width: 270px;
					}
	
					.phone-input {
						width: 250px;
					}
	
					.email-input {
						width: 290px;
					}
	
				}
			}
	
			//^----------------------------- table -------------------------------
		}
	}
}

@media (min-width: 414px) {
	.form-container {
		.form {

			.personnal-infos {
				width: 80%;
			}
	
			//^----------------------------- table -------------------------------
		}
	}
}

//^--------------------------------- TABLET -------------------------------------


@media (min-width: 599px) {

	.form-container {
		.form {
			.personnal-infos {
				width: 60%;
			}
	
			.input-container {
	
				& > .label {
					font-size: 1.5rem;
					margin: -0.3rem 1rem 0 0;
				}
			}
	
			.legend {
				width: 95%;

				font: {
					family: montserrat-regular;
					size: 1.5rem;
				}
				line-height: 1.9rem;
			}
	
			//^----------------------------- table -------------------------------
	
			.table-container {
	
				//^------------------------------ BUTTON ---------------------------------
	
				//^------------------------------ MESSAGE ---------------------------------
	
				.status-confirmation-message {
					font-size: 1.5rem;
					line-height: 1.9rem;
				}
	
				.payment-infos {
					font-size: 1.3rem;
					line-height: 1.7rem;
				}
			}
		}
	}
}

@media (min-width: 899px) {

}

//^--------------------------------- DESKTOP -------------------------------------


@media (min-width: 1199px) {

	.form-container {
		width: 1000px;

		.form {
			width: 90%;

			.personnal-infos {
				width: 75%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-evenly;
				margin: 0 auto;
			}
	
			.input-container {
	
				& > .label {
					font-size: 1.5rem;
					margin: -0.3rem 1rem 0 0;
				}
			}
	
			.legend {
				width: 95%;

				font: {
					family: montserrat-regular;
					size: 1.2rem;
				}
				line-height: 1.5rem;
			}
	
			//^----------------------------- table -------------------------------
	
			.table-container {
				margin: 0 auto;
				padding: 3rem;
	
				//^------------------------------ BUTTON ---------------------------------
	
				//^------------------------------ MESSAGE ---------------------------------
	
				table {
					width: 1130px;
				}

				.status-confirmation-message {
					font-size: 1.5rem;
					line-height: 1.9rem;
				}
	
				.payment-infos {
					font-size: 1.3rem;
					line-height: 1.7rem;
				}
			}
		}
	}

}

@media (min-width: 1399px) {

	.form-container {
		width: 1200px;
	
		.form {
			width: 90%;
		}
	}
}

@media (min-width: 1599px) {
	.form-container {
		width: 1400px;
	
		.form {
			width: 90%;
		}
	}
}

@media (min-width: 1920px) {
	.form-container {
		width: 1500px;
	
		.form {
			width: 90%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0 auto;
			padding-top: 3rem;
	
			.label {
				display: flex;
	
				.star {
					font-size: 1rem;
					margin-bottom: 2rem;
				}
			}
	
			& > * {
				margin-bottom: 0.5rem;
			}
	
			.personnal-infos {
				width: 75%;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-evenly;
				margin: 0 auto;
			}
	
			.name-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				
			}
	
			.other-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
	
			.input-container-fistname, .input-container-email {
				margin-top: 1rem;
			}
	
			.input-container {
				width: 500px;
				display: flex;
				justify-content: center;
	
				& > .label {
					font-family: oswald-regular;
					font-size: 1.5rem;
					text-transform: uppercase;
					color: $c-white;
				}
	
				.input-content {
					width: 100%;
	
					& > input {
						width: auto;
						height: 35px;
						border-radius: 10px;
						border: none;
						padding: 0 0.5rem;
						font-family: montserrat-regular;
						font-size: 1.2rem;
						color: $c-dark-green;
						box-shadow: inset 0px 10px 7px -10px $c-very-dark-green;
						margin: -0.5rem 0 0 1rem;
						background-color: $c-white;
					}
	
					.email-input {
						width: 400px;
					}
	
	
					.error-message {
						width: 100%;
						font-family: oswald-regular;
						color: $c-pink-cherry;
						margin-top: 0.5rem;
					}
				}
			}
	
			.span {
				font-family: montserrat-thin-italic;
				margin-top: 1rem;
			}
	
			.legend {
				font: {
					family: montserrat-regular;
					size: 1.2rem;
				}
				line-height: 1.5rem;
				margin: 1rem auto 3rem auto;
			}
	
			//^----------------------------- table -------------------------------
	
			.table-container {
				padding: 7rem 5rem 0rem 5rem;
	
				table {
					width: 100%;
				}
	
				.empty {
					width: 200px;
					background-color: transparent;
				}
	
				thead {
					tr {
						th {
							width: 280px;
							font-size: 1.2rem;
							font-family: oswald-semibold;
							text-transform: uppercase;
							padding: 1rem 0;
							background-color: $c-white;
							border-radius: 10px;
							color: $c-blood-cherry;
						}
					}
				}
	
				td {
					width: 150px;
					font-family: montserrat-regular;
					text-align: center;
					padding: 1rem 0;
	
					.input { // inputs nombre places spectacle
						width: 50%;
						text-align: center;
						border-radius: 10px;
						padding: 0.2rem 0;
						font-size: 1.2rem;
						color: $c-dark-green;
						box-shadow: inset 0px 10px 7px -10px $c-very-dark-green;
						background-color: $c-white;
					}
				}
	
				.show-name {  
					width: 300px;
					margin-top: -0.5rem;
				}
	
				.name-show-span {
					font-family: oswald-extra-light;
					color: $c-pink-cherry;
				}
	
				tbody {
					tr {
						.head {
							font-size: 1.1rem;
							font-family: oswald-semibold;
							text-transform: uppercase;
							padding: 1rem 0;
							background-color: #ced3cf31;
							border-radius: 10px;
						}
					}
				}
	
							//^------------------------------ BUTTON ---------------------------------
	
				.form-button {
					background-color: $c-white;
					color: $c-dark-cherry;
					box-shadow: 0px 10px 10px -10px $c-dark-green;
				
					.label {
						text-shadow: 0 0 0rem $c-dark-green;
					}
				
					&:hover {
						box-shadow: 0px 10px 20px -10px $c-dark-green;
						background-color: $c-very-white;
					}
				
					&:active {
						box-shadow: 0px 10px 10px -10px $c-dark-green;
					}
				}
	
				.status-confirmation-message {
					text-align: center;
					margin-top: 1rem;
					font-family: oswald-semibold;
					color: $c-white;
					font-size: 1.5rem;
				}
	
				.payment-infos {
					text-align: center;
					margin-top: 1rem;
					font-family: oswald-regular;
					color: $c-white;
					font-size: 1.2rem;
					line-height: 1.5rem;
					padding-bottom: 2rem;
				}
			}
		}
	}
}