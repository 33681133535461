//& Montserrat

@font-face {
	font-family: 'montserrat-black';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Black.ttf');
}

@font-face {
	font-family: 'montserrat-black-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-BlackItalic.ttf');
}

@font-face {
	font-family: 'montserrat-bold';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Bold.ttf');
}

@font-face {
	font-family: 'montserrat-bold-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-BoldItalic.ttf');
}

@font-face {
	font-family: 'montserrat-extra-bold';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-ExtraBold.ttf');
}

@font-face {
	font-family: 'montserrat-extra-bold-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-ExtraBoldItalic.ttf');
}

@font-face {
	font-family: 'montserrat-extra-light';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-ExtraLight.ttf');
}

@font-face {
	font-family: 'montserrat-extra-light-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-ExtraLightItalic.ttf');
}

@font-face {
	font-family: 'montserrat-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Italic.ttf');
}

@font-face {
	font-family: 'montserrat-light';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Light.ttf');
}

@font-face {
	font-family: 'montserrat-light-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-LightItalic.ttf');
}

@font-face {
	font-family: 'montserrat-medium';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Medium.ttf');
}

@font-face {
	font-family: 'montserrat-medium-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-MediumItalic.ttf');
}

@font-face {
	font-family: 'montserrat-regular';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Regular.ttf');
}

@font-face {
	font-family: 'montserrat-semibold';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-SemiBold.ttf');
}

@font-face {
	font-family: 'montserrat-semibold-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-SemiBoldItalic.ttf');
}

@font-face {
	font-family: 'montserrat-thin';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-Thin.ttf');
}

@font-face {
	font-family: 'montserrat-thin-italic';
	src: url('/fonts/Montserrat-Alternates/MontserratAlternates-ThinItalic.ttf');
}

//& Oswald
@font-face {
	font-family: 'oswald-bold';
	src: url('/fonts/Oswald/Oswald-Bold.ttf');
}

@font-face {
	font-family: 'oswald-extra-light';
	src: url('/fonts/Oswald/Oswald-ExtraLight.ttf');
}

@font-face {
	font-family: 'oswald-light';
	src: url('/fonts/Oswald/Oswald-Light.ttf');
}

@font-face {
	font-family: 'oswald-medium';
	src: url('/fonts/Oswald/Oswald-Medium.ttf');
}

@font-face {
	font-family: 'oswald-regular';
	src: url('/fonts/Oswald/Oswald-Regular.ttf');
}

@font-face {
	font-family: 'oswald-semibold';
	src: url('/fonts/Oswald/Oswald-SemiBold.ttf');
}

//& Bebas

@font-face {
	font-family: 'bebas';
	src: url('/fonts/Bebas/BebasNeue-Regular.ttf');
}














