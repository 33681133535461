.archives-container {
	min-width: 100%;
	max-width: 100vw;
	height: 100%;
	padding: 5rem 0 0 0;
	overflow-x: hidden;
	position: relative;

	.title {
		width: 100%;
		font-family: bebas;
		text-transform: uppercase;
		font-size: 4rem;
		line-height: 5rem;
		margin-bottom: 5rem;
		text-shadow: 0px 2px 3px $c-dark-green;
		color: $c-very-white;
		text-align: right;
		margin-right: 2rem;

		.title-span {
			margin-right: 1rem;
		}
	}
}

@media (min-width: 599px) {

	.archives-container {
		.title {
			font-size: 6rem;
			line-height: 7rem;
		}
	}
}

@media (min-width: 1199px) {

	.archives-container {
		.title {
			font-size: 8rem;
			line-height: 9rem;

			.title-span {
				margin-right: 2rem;
			}
		}
	}
}

@media (min-width: 1399px) {

	.archives-container {
		.title {
			font-size: 9rem;
			line-height: 10rem;
		}
	}
}
